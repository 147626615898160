import request from '../utils/request'

const Api = {
    Saved: 'tax/save',
    Overed: 'tax/over'
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Overed (data) {
    return request({
        url: Api.Overed,
        method: 'POST',
        data:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Saved (data) {
    return request({
        url: Api.Saved,
        method: 'POST',
        data:data
    })
}
