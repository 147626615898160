import request from '../utils/request'

const Api = {
    Goods: 'tax/kind-goods'
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Goods (data) {
    return request({
        url: Api.Goods,
        method: 'GET',
        params:data
    })
}
