<template>
  <div>
    <div class="invoice-detail-page">
      <van-notice-bar
          v-if="invoice.status>2 && invoice.status!==7 && invoice.status!==8"
          :scrollable="true"
          left-icon="volume-o"
          :text="'申请后请在下午3点30分前扫码开票，逾期申请将作废。'"
      />
      <van-form @submit="onSubmit" class="submit-data">
        <van-field v-model="invoice.order_no"   label="申请编号" model-value="提交后自动生成" disabled  />
        <van-field v-model="invoice.apply_time"   label="申请日期" readonly  />
        <van-field v-model="invoice.invoice_type"   label="发票类型" model-value="增值税普通发票" readonly  />
        <van-field
            readonly
            clickable
            required
            v-model="invoice.type"
            label="购买方类型"
            placeholder="请选择"
            @click="showInvoiceTypePopup"
        />
        <!-- 按钮-->
        <van-button type="warning" style="border-radius: 4px;" size="normal" icon="plus" :disabled="no_click" class="invoice-add-button" @click="showAddInvoicePopup">添加开票内容</van-button>
        <!--发票内容-->
        <van-cell-group class="invoice-goods" v-if="show_goods">
          <van-cell>
            <van-col :span="24">发票填写内容<span style="color: #999999">（最多8个）</span></van-col>
          </van-cell>
          <van-swipe-cell :disabled="invoice.status > 1 ? true : false" :name="JSON.stringify(item)" :before-close="beforeClose" v-for="item in invoice_detail" :key="item.id">
            <van-cell @click="showAddInvoicePopup(item)" style="height: auto;">
              <van-col span="24" class="invoice-desc">
                <span class="font-color">名称：{{ item.name }}</span>
              </van-col>
              <van-col span="24" class="invoice-desc">
                <van-row>
                  <van-col :span="12" class="invoice-desc">
                    <span class="font-color">货物单位：{{ item.unit }}</span>
                  </van-col>
                  <van-col :span="12" class="invoice-desc">
                    <span class="font-color">货物单价：{{ item.price }}</span>
                    <van-icon name="arrow" class="goods-icon"/>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24" class="invoice-desc">
                <span class="font-color">含税金额：{{ item.tax_amount }}</span>
              </van-col>
            </van-cell>
            <template #right>
              <van-button square type="danger" text="删除" class="delete-button"/>
            </template>
          </van-swipe-cell>
        </van-cell-group>
        <h2 class="block-title">
          <span>本月已申请不含税金额：{{ no_sum_tax_amount}} </span>
          <van-popover v-model:show="showPopover" :actions="actions"  placement="top-start" style="align-items: end;">
            <template #reference>
              <van-icon name="question-o" color="#FF552E" size="15" style="margin-left: 10px;line-height: 16px;vertical-align: middle"/>
            </template>
          </van-popover>
        </h2>
        <van-cell-group class="invoice-tax" v-if="no_tax_show">
          <van-cell center title="含税金额小计" :value="(invoice_tax.tax_amount)" class="tax-cell"></van-cell>
<!--          <van-cell center title="含税金额小计" :value="(invoice_tax.tax_amount==0 ? invoice_tax.tax_amount.toFixed(2) : invoice_tax.tax_amount)" class="tax-cell"></van-cell>-->
          <van-cell center title="不含税金额小计" :value="(invoice_tax.amount)" class="tax-cell"></van-cell>
          <van-cell center title="需缴纳税额小计" :value="(invoice_tax.tax)" class="tax-cell" is-link @click="showPopupTax"></van-cell>
        </van-cell-group>
        <van-cell-group class="invoice-info">
          <van-field
              v-if="(invoice.type=='企业或其他纳税人')"
              v-model="invoice.company_name"
              required
              @keyup.down="down_name(invoice.company_name)"
              @input="down_name($event)"
              label="开票单位"
              :disabled="invoice.status < 2 ? false : true"
              placeholder="请输入"/>
<!--          <ul  class="invoice_info" v-if="show_list_data">-->
<!--            <li v-for="item in list" style="font-size: 12px;line-height: 12px;padding: 10px;color: #323233" @click="choose_name(item)">-->
<!--              {{ item.company_name }}-->
<!--            </li>-->
<!--          </ul>-->
          <van-popover v-if="show_list_data" v-model:show="show_invoice_list"  placement="bottom-end"  class="action_list">
              <van-list>
                <van-cell v-for="item in list" :key="item" :title="item.company_name" @click="choose_name(item)" style="text-align: right;font-size: 0.35rem;" />
              </van-list>
          </van-popover>
          <van-field
              v-if="(invoice.type=='个人') || (invoice.type=='非企业单位')"
              v-model="invoice.company_name"
              required
              label="发票抬头"
              :disabled="invoice.status < 2 ? false : true"
              placeholder="请输入"/>
          <van-field
              label-width="30%"
              v-if="(invoice.type=='企业或其他纳税人')"
              v-model="invoice.taxpayer_id_no"
              required
              label="纳税人识别号"
              :disabled="invoice.status < 2 ? false : true"
              placeholder="请输入"
              :rules="[{pattern: /^\w{18,}$/,message:'位数不少于18位'}]"
          />
          <van-field
              v-if="invoice.type=='企业或其他纳税人'"
              v-model="invoice.address"
              label="地址"
              :disabled="invoice.status < 2 ? false : true"
              placeholder="请输入"/>
          <van-field
              v-if="invoice.type=='企业或其他纳税人'"
              v-model="invoice.mobile"
              label="电话号码"
              type="number"
              :disabled="invoice.status < 2 ? false : true"
              placeholder="请输入"/>
          <van-field
              v-if="invoice.type=='企业或其他纳税人'"
              v-model="invoice.account_opening_bank"
              label="开户银行"
              :disabled="invoice.status < 2 ? false : true"
              placeholder="请输入"/>
          <van-field
              v-if="invoice.type=='企业或其他纳税人'"
              v-model="invoice.bank_card_no"
              label="银行账号"
              type="number"
              :disabled="invoice.status < 2 ? false : true"
              placeholder="请输入"/>
        </van-cell-group>
        <van-cell-group class="invoice-end" v-if="invoice.status===6 || invoice.status===8">
          <div v-if="invoice.pick_up_time<=currun_time">
            <van-cell center title="开票日期" :value="(invoice.invoice_time)" class="tax-cell"></van-cell>
            <van-cell center title="发票号" :value="(invoice.invoice_no)" class="tax-cell"></van-cell>
            <van-cell center title="电子纳税号" :value="(invoice.tax_no)" class="tax-cell"></van-cell>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <!-- 提交按钮 -->
    <div class="submit-button-group" v-if="invoice.status<4">
    <van-button style="border-radius: 4px;" square block type="default" v-if="invoice.status<2" @click="onSave" :disabled="no_submit" native-type="submit">
      保存
    </van-button>
    <van-button style="border-radius: 4px;border: unset;" block type="warning" class="submit-button-btn" v-if="invoice.status<2" :disabled="no_submit" @click="onSubmit" native-type="submit">
      提交
    </van-button>
    <van-button style="border-radius: 4px;border: unset;" block type="warning" class="submit-button-btn" v-if="invoice.status===2" :to="{ path: '/pay/index', query: { id: invoice.id }}">
      去支付
    </van-button>
  </div>
  <!-- <div class="submit-button-group" v-if="invoice.status===6 && (invoice.pick_up_time<=currun_time)"> -->
  <div class="submit-button-group" v-if="invoice.status===6">
    <van-button style="border-radius: 4px;border: unset;" block type="warning" class="submit-button-btn"  @click="confirmStatus">
      我已领票
    </van-button>
  </div>

    <!-- 商品添加弹层 -->
    <van-popup
        class="add-invoice-popup"
        v-model:show="add_invoice_show"
        closeable
        position="bottom"
        :style="{ height: '70%' }">
      <h3 class="tax-pop-title">填写发票内容</h3>
      <van-cell class="item-edit">
        <van-field
            label-width="60%"
            input-align="right"
            required
            label="货物或应税劳务、服务名称"
            :disabled="invoice.status < 2 ? false : true"
            v-model="edit_item.name" placeholder="请选择" readonly clearable @click="showGoodsPopup"/>
        <van-field
            readonly
            clickable
            input-align="right"
            v-model="edit_item.specification"
            placeholder="请选择"
            :disabled="invoice.status < 2 ? false : true"
            label="货物规格型号"  @click="showSpecPopup"/>
        <van-field
            readonly
            clickable
            input-align="right"
            v-model="edit_item.unit"
            placeholder="请选择"
            :disabled="invoice.status < 2 ? false : true"
            label="货物单位"
            @click="showUnitPopup"
        />
        <van-field
            label-width="50%"
            input-align="right"
            v-model="edit_item.tax_amount"
            required
            type="number"
            :disabled="invoice.status < 2 ? false : true"
            @keyup.down="down_price(edit_item.quantity)"
            @input="down_price($event)"
            placeholder="请输入"
            label="开票金额（含税）"/>
        <van-field
            input-align="right"
            v-model="edit_item.quantity"
            placeholder="请输入"
            type="number"
            :disabled="invoice.status < 2 ? false : true"
            @keyup.down="down_price(edit_item.quantity)"
            @input="down_price($event)"
            label="数量"/>
        <van-field
            input-align="right"
            v-model="edit_item.price"
            placeholder="根据数量和金额自动计算"
            type="number"
            disabled
            label="货物单价"/>
      </van-cell>

      <van-cell class="van-button-goods">
        <van-button style="border-radius: 4px;" block type="warning"  v-if="invoice.status < 2"  @click="submitGoods">确定</van-button>
      </van-cell>

    </van-popup>

    <!-- 发票Item添加弹层 -->
    <van-popup
        class="tax-popup"
        v-model:show="tax_show"
        closeable
        position="bottom"
        :style="{ height: '50%' }">
      <h3 class="tax-pop-title">需缴纳税费明细</h3>
      <van-cell-group class="tax-edit">
        <van-cell center title="增值税" :value="(tax_detail.tax_value)" class="tax-cell"></van-cell>
        <van-cell center title="个人所得税" :value="(tax_detail.tax_income)" class="tax-cell"></van-cell>
        <van-cell center title="城市维护建设税" :value="(tax_detail.tax_city)" class="tax-cell"></van-cell>
        <van-cell center title="教育附加" :value="(tax_detail.tax_education)" class="tax-cell"></van-cell>
        <van-cell center title="地方教育费附加" :value="(tax_detail.tax_local_education)" class="tax-cell"></van-cell>
      </van-cell-group>
    </van-popup>

    <!-- 购买方类型 -->
    <van-popup
        v-model:show="showInvoiceTypePicker"
        round
        position="bottom"
        :style="{ height: '60%' }"
    >
      <van-picker
          title="开票类型"
          :columns="columns"
          value-key="values"
          @cancel="oncancel2"
          @confirm="onConfirm2"
      />
    </van-popup>

    <!-- 商拼添加 -->
    <van-popup
        v-model:show="showGoodsPicker"
        round
        name="item-a"
        class="item-b"
        :key="timer"
        position="bottom"
        :style="{ height: '60%' }"
    >
      <van-picker
          name="item"
          class="item"
          title="品类"
          :columns="kind_goods"
          @cancel="cnacel3"
          @confirm="onConfirm3"
      />
    </van-popup>

    <!-- 货物单位 -->
    <van-popup
        v-model:show="showUnitPicker"
        round
        name="item-a"
        class="item-b"
        :key="timer"
        position="bottom"
        :style="{ height: '60%' }"
    >
      <van-picker
          name="item"
          class="item"
          title="货物单位"
          :columns="unit_list"
          @cancel="cnacelUnit"
          @confirm="onConfirmUnit"
      />
    </van-popup>
    <!-- 货物规格 -->
    <van-popup
        v-model:show="showSpecPicker"
        round
        name="item-a"
        class="item-b"
        :key="timer"
        position="bottom"
        :style="{ height: '60%' }"
    >
      <van-picker
          name="item"
          class="item"
          title="货物规格"
          :columns="spec_list"
          @cancel="cnacelSpec"
          @confirm="onConfirmSpec"
      />
    </van-popup>

<!--    <van-dialog v-model:show="show_receive"  title="请再次确认发票信息"	width="75%"  :style="{ height: '250px' }" @confirm="confirmStatus" show-cancel-button>-->
<!--      <van-cell center title="开票日期" :value="(invoice.invoice_time)" class="tax-cell"></van-cell>-->
<!--      <van-cell center title="发票号" :value="(invoice.invoice_no)" class="tax-cell"></van-cell>-->
<!--      <van-cell center title="电子纳税号" :value="(invoice.tax_no)" class="tax-cell"></van-cell>-->
<!--    </van-dialog>-->
  </div>
</template>
<script>

import {reactive, ref, toRefs} from "vue";
import {InvoiceDetail} from "../../api/invoice";
import {PayTax} from "../../api/invoice";
import {Exclude} from "../../api/invoice";
import {Goods} from "../../api/goods";
import {Saved,Overed} from "../../api/saved";
// import {getInvoiceList} from "../../api/rise";
import {getInvoiceInfo} from "../../api/rise";
import {Pick} from "../../api/invoice";
import {Toast,Dialog } from "vant";
import {useRouter} from "vue-router";
export default {


  setup() {
    const router = useRouter();
    const state = reactive({
      payData:{},
      show_goods:false,//填写发票内容是否显示
      no_tax_show:false,//含税金额小计不显示
      no_click:false,//添加开票内容是否点击
      no_submit:true,//提交按钮不可点击
      show_save:true,//保存按钮显示
      show_submit:true,//提交按钮显示
      no_sum_tax_amount:'0.00',//本月已申请不含税金额
      unit_list:[],
      spec_list:[],
      receive_list:[],
      currun_time:'',
      invoice:{
        id:null,
        invoice_type:'增值税普通发票',
        order_no: '',
        apply_time: new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),
        status: '',
        type:'',
        type_index:'',
        company_name:'',
        merchant_name:JSON.parse(localStorage.getItem('user')).merchant_name,
        merchant_id:JSON.parse(localStorage.getItem('user')).id,
        stall_no:JSON.parse(localStorage.getItem('user')).stall_no,
        stall_area:JSON.parse(localStorage.getItem('user')).stall_area,
        vendor_id_no:JSON.parse(localStorage.getItem('user')).taxpayer_id_no,
        merchant_type:JSON.parse(localStorage.getItem('user')).merchant_type,
        taxpayer_id_no:'',
        address:'',
        mobile:'',
        account_opening_bank:'',
        bank_card_no:'',
        total_tax:'',//缴税小计
        pick_up_time:'',
        over_type:1,
      },//基础信息
      invoice_detail:[],//详情
      delete_invoice_detail:[],//保存后详情删除数据
      kind_goods:[],//弹窗货物列表
      edit_item:{}, //添加编辑发票详情
      edit_index:'',
      invoice_tax:{
        amount:'0.00',
        tax_amount:'0.00',
        tax:'0.00',
      },
      num:'',
      tax_detail:{
        'tax_value':'0.00',//增值税
        'tax_income':'0.00',//个人所得税
        'tax_city':'0.00',//城市维护建设税
        'tax_education':'0.00',//教育费附加
        'tax_local_education':'0.00'//地方教育附加
      },
      pay_tax_detail:[],
      showPopover:false,
      show_invoice_list:false,
      list:[],
      list_data:[],
      show_list_data:false,
      length:0,
      timer: '',//弹窗进来一次刷新一次，永远是默认选择第一个
    });
    // 通过 actions 属性来定义菜单选项
    //本月累计已提交成功的不含税金额小计，不包含本次申请。
    const actions = [
      { text: '本月累计已提交成功的不含税金额小计，不包含本次申请。',className:'question_mark' },
    ];

    // 需缴纳税计算
    const tax_show = ref(false);
    const showPopupTax = () => {
      tax_show.value = true;
    };
    // 商品选择弹层
    const showGoodsPicker = ref(false);
    const showGoodsPopup = () => {
      if(state.invoice.status<2){
        showGoodsPicker.value = true;
      }else{
        showGoodsPicker.value = false;
      }
    };
    // 弹窗填写货物内容
    const cnacel3 = () =>{
      showGoodsPicker.value = false;
    };
    const onConfirm3 = (value) => {
      state.edit_item.name = value;
      showGoodsPicker.value = false;
    };
    //货物单位弹层
    const showUnitPicker = ref(false);
    const showUnitPopup = () => {
      if(state.invoice.status<2){
        showUnitPicker.value = true;
      }else{
        showUnitPicker.value = false;
      }
    };
    // 弹窗填写货物单位内容
    const cnacelUnit = () =>{
      showUnitPicker.value = false;
    };
    const onConfirmUnit = (value) => {
      state.edit_item.unit = value;
      showUnitPicker.value = false;
    };

    //货物单位弹层
    const showSpecPicker = ref(false);
    const showSpecPopup = () => {
      if(state.invoice.status<2){
        showSpecPicker.value = true;
      }else{
        showSpecPicker.value = false;
      }
    };
    // 弹窗填写货物单位内容
    const cnacelSpec = () =>{
      showSpecPicker.value = false;
    };
    const onConfirmSpec = (value) => {
      state.edit_item.specification = value;
      showSpecPicker.value = false;
    };


    // 发票类型选择弹层
    const columns = ['个人', '企业或其他纳税人', '非企业单位'];
    const showInvoiceTypePicker = ref(false);
    const showInvoiceTypePopup = () => {
      showInvoiceTypePicker.value = true;
    };
    // 发票详情添加
    const add_invoice_show = ref(false);
    //详情发票内容
    const showAddInvoicePopup = (item) => {
      state.edit_index = state.invoice_detail.indexOf(item)
      state.timer = new Date().getTime();//弹窗进来一次刷新一次，永远是默认选择第一个

      const user = JSON.parse(localStorage.getItem('user'));
      const stall_area_type = user.stall_area;
      Goods({stall_area:stall_area_type})
          .then(function (res){
            if(res.Code===200){
              state.kind_goods = res.Data;
            }
          }).catch(function (err){
            console.info(err)
      })
      if(item.name === undefined){
        state.edit_item = {
          id: null,
          name:'',
          specification:'',
          unit:'',
          quantity:'',
          price:'',
          tax_amount:''
        }

      }else {
        state.edit_item= item
      }
      add_invoice_show.value = true;
      //判断如果提交了就不能出现弹窗
      // if(state.invoice.status >1){
      //   add_invoice_show.value = false;
      // }else{
      //   add_invoice_show.value = true;
      // }

    };
    //填写货物内容提交
    const submitGoods = () => {
      if(state.invoice_detail.length > 8){
        Toast({
          type: "error",
          message: '内容最多有8个',
          duration: 1000,
        });
        return false;
      }else{
        if(state.edit_item.unit.length>6){
          Toast({
            message: '货物单位长度不能大于6',
            duration: 1 * 1000,
          });
          return false;
        }
        //填写内容，确定按钮是否可点击
        const name = state.edit_item.name;
        const tax_amount = state.edit_item.tax_amount;
        if(name=='' || tax_amount==''){
          Toast({
            type: "error",
            message: '名称或含税金额未填写',
            duration: 1 * 1000,
          });
        }else{
          //输入的含税金额只保留两位小数，不四舍五入
          state.edit_item.tax_amount  = ( parseInt(  state.edit_item.tax_amount * 100 ) / 100 ).toFixed(2);
          if(state.edit_index === -1){
            state.invoice_detail.push(state.edit_item);
          }else {
            state.invoice_detail[state.edit_index]= state.edit_item;
          }
          if(state.invoice_detail.length>0){//含税金额小计显示
            state.no_tax_show = true;
            state.no_submit = false;//提交按钮可点击
          }

          if(state.invoice_detail.length==8){
            state.no_click = true;//添加开票内容不可点击
          }

          const user = JSON.parse(localStorage.getItem('user'));
          const merchant_id = user.id;
          const invoice_detail = state.invoice_detail;
          //计算税务 异步更新数据
          PayTax({merchant_id:merchant_id,orderDetail:invoice_detail}).then(function (res){
            if(res.Code==200){
              state.invoice_tax.tax_amount = res.Data.tax_included_amount;
              state.invoice_tax.amount = res.Data.no_tax_included_amount;
              state.invoice_tax.tax = res.Data.tax.total_tax;//缴税小计
              state.tax_detail.tax_value = res.Data.tax.tax_value_per;//增值税
              state.tax_detail.tax_income = res.Data.tax.tax_income_per;//个数
              state.tax_detail.tax_city = res.Data.tax.tax_city_per;//城市
              state.tax_detail.tax_education = res.Data.tax.tax_education_per;//教育
              state.tax_detail.tax_local_education = res.Data.tax.tax_local_education_per;//地方教育
              state.pay_tax_detail = res.Data.tax;
              if(res.Data.tax.total_tax!==0){
                state.invoice.total_tax = res.Data.tax.total_tax;
              }
            }
          }).catch(function (err){
            console.info(err)
          })
        }

      }
      if(state.invoice_detail.length>0){
          state.show_goods = true;
      }
      add_invoice_show.value = false;
    };
    //保存数据
    const onSave = async() =>{
      let orderBase = state.invoice;
      let orderDetail = state.invoice_detail;
      let deleteOrderDetail = state.delete_invoice_detail;
      let tax_detail =state.pay_tax_detail;
      state.no_submit = true;
      // 三秒生效
      setTimeout(function(){
        state.no_submit = false;
        console.info('3')
      }, 3000);
      //异步更新数据
      Saved({type:1,orderBase:orderBase,orderDetail:orderDetail,deleteOrderDetail:deleteOrderDetail,taxDetail:tax_detail}).then(function (res){
        if(res.Code==200){
          Toast({
            type: "success",
            message: res.Msg,
            duration: 2 * 1000,
          });
          router.push('/tax/list');
        }
      }).catch(function (err){
        console.info(err)
      })

    };
    //开票类型
    const oncancel2 = () => {
      showInvoiceTypePicker.value = false;
    };
    const onConfirm2 = (value,index) => {
      state.invoice.type = value;
      state.invoice.type_index = index+1;
      showInvoiceTypePicker.value = false;
    };
    //提交数据
    const onSubmit = async() =>{
      let orderBase = state.invoice;
      let orderDetail = state.invoice_detail;
      let deleteOrderDetail = state.delete_invoice_detail;
      let tax_detail =state.pay_tax_detail;
      if(orderDetail.length==0){
        Toast({
          message: '请选择添加货物',
        });
        return;
      }
      if(state.invoice.type_index == ""){
        Toast({
          message: '请选择购买方类型',
        });
        return;
      }
      if(state.invoice.type_index==1 || state.invoice.type_index==3){
        state.invoice.address='';
        state.invoice.taxpayer_id_no='';
        state.invoice.mobile='';
        state.invoice.account_opening_bank='';
        state.invoice.bank_card_no='';

        if(state.invoice.company_name==''){
            Toast({
              message: '请填写发票抬头',
            });
          return;
        }
      }else{
        if(state.invoice.company_name==''){
          Toast({
            message: '请填写开票单位',
          });
          return;
        }
        if(state.invoice.taxpayer_id_no==''){
          Toast({
            message: '请填写纳税人识别号',
          });
          return;
        }
      }
      state.no_submit = true;
      // 三秒生效
      setTimeout(function(){
        state.no_submit = false;
      }, 3000);
      //判断连续三个月超18万，给的提示
      Overed({apply_time:state.invoice.apply_time,amount:state.invoice_tax.amount,}).then(function (res){
        if(res.Data==111){
          Dialog.confirm({
            title: '提示',
            message: res.Msg,
          })
              .then(() => {
                if(state.invoice_tax.tax==0.00){
                  Dialog.confirm({
                    title: '提示',
                    message: '提交后不可更改，请确认内容是否正确。',
                  })
                      .then(() => {

                        orderBase.over_type =2;
                        //异步更新数据
                        Saved({type:2,orderBase:orderBase,orderDetail:orderDetail,deleteOrderDetail:deleteOrderDetail,taxDetail:tax_detail}).then(function (res){
                          if(res.Code==200){
                            Toast({
                              type: "success",
                              message: '提交成功',
                              duration: 2 * 1000,
                            });
                            let result = res.Data;
                            if(result.is_pay===false){
                              router.push('/tax/list');
                            }else {
                              router.push({ path: '/pay/index', query: { id: result.invoice_id }});
                            }

                          }
                        }).catch(function (err){
                          console.info(err)
                        })
                      })
                      .catch(() => {
                        // on cancel
                      });
                }else{
                  // state.no_submit = true;
                  // // 三秒生效
                  // setTimeout(function(){
                  //   state.no_submit = false;
                  // }, 3000);
                  orderBase.over_type =2;
                  //异步更新数据
                  Saved({type:2,orderBase:orderBase,orderDetail:orderDetail,deleteOrderDetail:deleteOrderDetail,taxDetail:tax_detail}).then(function (res){
                    if(res.Code==200){
                      Toast({
                        type: "success",
                        message: '提交成功',
                        duration: 2 * 1000,
                      });
                      let result = res.Data;
                      if(result.is_pay===false){
                        router.push('/tax/list');
                      }else {
                        router.push({ path: '/pay/index', query: { id: result.invoice_id }});
                      }

                    }
                  }).catch(function (err){
                    console.info(err)
                  })
                }
              })
              .catch(() => {
                // on cancel
              });
        }else{
          if(state.invoice_tax.tax==0.00){
            Dialog.confirm({
              title: '提示',
              message: '提交后不可更改，请确认内容是否正确。',
            })
                .then(() => {
                  // state.no_submit = true;
                  // // 三秒生效
                  // setTimeout(function(){
                  //   state.no_submit = false;
                  //   console.info('3')
                  // }, 3000);
                  //异步更新数据
                  Saved({type:2,orderBase:orderBase,orderDetail:orderDetail,deleteOrderDetail:deleteOrderDetail,taxDetail:tax_detail}).then(function (res){
                    if(res.Code==200){
                      Toast({
                        type: "success",
                        message: '提交成功',
                        duration: 2 * 1000,
                      });
                      let result = res.Data;
                      if(result.is_pay===false){
                        router.push('/tax/list');
                      }else {
                        router.push({ path: '/pay/index', query: { id: result.invoice_id }});
                      }

                    }
                  }).catch(function (err){
                    console.info(err)
                  })
                })
                .catch(() => {
                  // on cancel
                });
          }else{
            // state.no_submit = true;
            // // 三秒生效
            // setTimeout(function(){
            //   state.no_submit = false;
            //   console.info('3')
            // }, 3000);
            //异步更新数据
            Saved({type:2,orderBase:orderBase,orderDetail:orderDetail,deleteOrderDetail:deleteOrderDetail,taxDetail:tax_detail}).then(function (res){
              if(res.Code==200){
                Toast({
                  type: "success",
                  message: '提交成功',
                  duration: 2 * 1000,
                });
                let result = res.Data;
                if(result.is_pay===false){
                  router.push('/tax/list');
                }else {
                  router.push({ path: '/pay/index', query: { id: result.invoice_id }});
                }

              }
            }).catch(function (err){
              console.info(err)
            })
          }
        }
      }).catch(function (err){
        console.info(err)
      })
    };

    // const show_receive = ref(false);
    // const  onPickUp = async() =>{
    //   var invoice_time = '开票日期：'+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"+state.invoice.invoice_time;
    //   var invoice_no = '发票号：'+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"+state.invoice.invoice_no;
    //   var tax_no = '纳税号：'+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"+state.invoice.tax_no;
    //  if(state.invoice.tax_no == "undefined" || state.invoice.tax_no == null || state.invoice.tax_no == ""){
    //    // state.receive_list = [invoice_time,invoice_no];
    //    state.receive_list = [
    //      {text:invoice_time, disabled: true },
    //      {text:invoice_no, disabled: false },
    //    ];
    //  }else{
    //    // state.receive_list = [invoice_time,invoice_no,tax_no];
    //    state.receive_list = [
    //      {text:invoice_time, disabled: true },
    //      {text:invoice_no, disabled: false },
    //      {text:tax_no, disabled: true },
    //    ];
    //
    //  }
    //   show_receive.value = true;
    // };
    // const cnacelStatus = async () =>{
    //   show_receive.value = false;
    // };
    const confirmStatus = async() =>{
      // var myDate = new Date();
      // var hour = myDate.getHours();
      // if(hour < 9 || hour > 14){
      //   Toast({
      //     message: '请在每天9:00-13:59领票时间内领取发票。',
      //   });
      //   return false;
      // }
      let id = state.invoice.id;
      Pick({id:id}).then(function (res){
        if(res.Code==200){
          Toast({
            type: "success",
            message: res.Msg,
            duration: 2 * 1000,
          });
          router.push('/tax/list');
        }
      }).catch(function (err){
        console.info(err)
      })
    };


    // // 获取用户信息
    // const merchantInfo =(value) =>{
    //   const taxpayer_id_no = value;
    //   //异步更新数据
    //   getMerchantInfo({taxpayer_id_no:taxpayer_id_no}).then(function (res){
    //     if(res.Code==200){
    //       const info = res.Data;
    //       state.invoice.company_name = info.company_name;
    //       state.invoice.address = info.address;
    //       state.invoice.mobile = info.mobile;
    //       state.invoice.account_opening_bank = info.account_opening_bank;
    //       state.invoice.account_opening_bank = info.account_opening_bank;
    //       // window.location.href = 'tax/add?id='+res.Data.id;
    //     }
    //   }).catch(function (err){
    //      console.info(err)
    //   })
    // };

    //匹配获取的内容
    const down_name = () =>{
      //过滤方法
      let _search = state.invoice.company_name;
      let length  = _search.length;
      if (_search) {
        state.show_list_data = true;
        state.show_invoice_list = true;
        if(length > state.length){
          //不区分大小写处理
          let reg = new RegExp(_search, 'ig')
          //es6 filter过滤匹配，有则返回当前，无则返回所有
          let data =  state.list_data.filter(function(value) {
            return value.company_name.match(reg);
          })
          if(data.length==0){
            state.show_list_data = false;
            state.show_invoice_list = false;
          }else{
            if(data.length>5){
              state.list = data.slice(0,5);
              state.length = length;
            }else{
              state.list = data;
              state.length = length;
            }
          }
        }else{
          // state.list = state.list_data;
          //不区分大小写处理
          let reg = new RegExp(_search, 'ig')
          //es6 filter过滤匹配，有则返回当前，无则返回所有
          let data =  state.list_data.filter(function(value) {
            return value.company_name.match(reg);
          })
          if(data.length>5){
            state.list = data.slice(0,5);
            state.length = length;
          }else{
            state.list = data;
            state.length = length;
          }

        }
      }else{
        state.show_list_data = false;
        state.show_invoice_list = false;
      }
    };
    //选择内容
    const choose_name = (value) =>{
      state.invoice.company_name = value.company_name;
      state.show_list_data = false;
      let id = value.id;
      getInvoiceInfo({id:id}).then(function (res){
        if(res.Code==200){
          const invoice_info = res.Data;
          state.invoice.taxpayer_id_no =  invoice_info.taxpayer_id_no;
          state.invoice.address =  invoice_info.address;
          state.invoice.mobile =  invoice_info.mobile;
          state.invoice.account_opening_bank =  invoice_info.account_opening_bank;
          state.invoice.bank_card_no =  invoice_info.bank_card_no;
        }
      }).catch(function (err){
        console.info(err)
      })
    };
    //计算单价
    const down_price = () =>{
      let tax_amount = state.edit_item.tax_amount;
      let quantity = state.edit_item.quantity;
      if(tax_amount!=='' && quantity!==''){
          state.edit_item.price = (tax_amount/quantity).toFixed(2);
      }
    }


    // position 为关闭时点击的位置
    const beforeClose = ({ name,position}) => {
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
          return true;
        case 'right':
          return new Promise(() => {
            Dialog.confirm({
              message: '确定删除吗？',
            }).then(() => {
                let jsObject = JSON.parse(name);  //转换为json对象
                let id = jsObject.id;
                let fruit_name = jsObject.name;
                let tax_amount = jsObject.tax_amount;
                if(id==null){//id为null直接删除
                    for (let i in state.invoice_detail) {
                      if(state.invoice_detail[i]['tax_amount'] == tax_amount && state.invoice_detail[i]['name'] == fruit_name){
                        state.invoice_detail.splice(i,1);
                      }
                      if(state.invoice_detail.length==0){//判断是否删除完，删除完，含税金额小计不显示
                          state.no_tax_show = false;
                      }else{//判断是否删除完，没删除完，含税金额小计重新计算
                        const user = JSON.parse(localStorage.getItem('user'));
                        const merchant_id = user.id;
                        const invoice_detail = state.invoice_detail;
                        //计算税务 异步更新数据
                        PayTax({merchant_id:merchant_id,orderDetail:invoice_detail}).then(function (res){
                          if(res.Code==200){
                            state.invoice_tax.tax_amount = res.Data.tax_included_amount;
                            state.invoice_tax.amount = res.Data.no_tax_included_amount;
                            state.invoice_tax.tax = res.Data.tax.total_tax;//缴税小计
                            state.tax_detail.tax_value = res.Data.tax.tax_value;//增值税
                            state.tax_detail.tax_income = res.Data.tax.tax_income;//个数
                            state.tax_detail.tax_city = res.Data.tax.tax_city;//城市
                            state.tax_detail.tax_education = res.Data.tax.tax_education;//教育
                            state.tax_detail.tax_local_education = res.Data.tax.tax_local_education;//地方教育
                            state.pay_tax_detail = res.Data.tax;
                            if(res.Data.tax.total_tax!==0){
                              state.invoice.total_tax = res.Data.tax.total_tax;
                            }
                          }
                        }).catch(function (err){
                          console.info(err)
                        })
                      }
                    }
                }else{//id不为null删除
                    for (let i in state.invoice_detail) {
                      if(state.invoice_detail[i]['id'] == id){
                        state.delete_invoice_detail.push(state.invoice_detail[i]['id']);
                        state.invoice_detail.splice(i,1);
                      }
                    }
                    if(state.invoice_detail.length==0){//判断是否删除完，删除完，含税金额小计不显示
                      state.no_tax_show = false;
                    }else{//判断是否删除完，没删除完，含税金额小计重新计算
                      const user = JSON.parse(localStorage.getItem('user'));
                      const merchant_id = user.id;
                      const invoice_detail = state.invoice_detail;
                      //计算税务 异步更新数据
                      PayTax({merchant_id:merchant_id,orderDetail:invoice_detail}).then(function (res){
                        if(res.Code==200){
                          state.invoice_tax.tax_amount = res.Data.tax_included_amount;
                          state.invoice_tax.amount = res.Data.no_tax_included_amount;
                          state.invoice_tax.tax = res.Data.tax.total_tax;//缴税小计
                          state.tax_detail.tax_value = res.Data.tax.tax_value;//增值税
                          state.tax_detail.tax_income = res.Data.tax.tax_income;//个数
                          state.tax_detail.tax_city = res.Data.tax.tax_city;//城市
                          state.tax_detail.tax_education = res.Data.tax.tax_education;//教育
                          state.tax_detail.tax_local_education = res.Data.tax.tax_local_education;//地方教育
                          state.pay_tax_detail = res.Data.tax;
                          if(res.Data.tax.total_tax!==0){
                            state.invoice.total_tax = res.Data.tax.total_tax;
                          }
                        }
                      }).catch(function (err){
                        console.info(err)
                      })
                    }
                }


                })
                .catch(() => {
                  // on cancel
                });
          });
      }
    };
    return {
      beforeClose,
      actions,
      columns,
      showInvoiceTypePicker,
      showInvoiceTypePopup,
      // 添加或编辑弹层
      add_invoice_show,
      showAddInvoicePopup,
      // 商品选择弹层
      showGoodsPicker,
      showGoodsPopup,
      // 各种税弹层
      tax_show,
      showPopupTax,
      ...toRefs(state),
      submitGoods,
      onSave,
      onSubmit,
      // onConfirm,
      oncancel2,
      onConfirm2,
      cnacel3,
      onConfirm3,
      choose_name,
      down_name,
      down_price,
      // merchantInfo,
      showUnitPopup,
      showUnitPicker,
      cnacelUnit,
      onConfirmUnit,
      showSpecPopup,
      showSpecPicker,
      cnacelSpec,
      onConfirmSpec,
      confirmStatus,
    };
  },
  created: function () {
    this.detail();
    this.invoiceList();
  },
  methods:{
    detail(){
      let _this = this
      let id = _this.$route.query.id;
      if(id !== '0'){
        // 异步更新数据
        InvoiceDetail({id:id}).then(function (res){
          if(res.Code==200){
            let info = res.Data.info;
            let detail = res.Data.detail;
            let tax_info = res.Data.tax_info;
            let no_sum_tax_amount = res.Data.no_sum_tax_amount;//这个月不含税
            let tax_list_data = res.Data.tax_list_data;//缴税金额信息
            _this.unit_list = res.Data.unit_list;//单位
            _this.spec_list = res.Data.spec_list;//规格
            _this.currun_time = res.Data.currun_time;
            if(info.type==1){
              info.type = '个人';
              info.type_index = 1;
            }else if(info.type==2){
              info.type = '企业或其他纳税人';
              info.type_index = 2;
            }else{
              info.type = '非企业单位';
              info.type_index = 3;
            }
            if(info.status < 2){
              _this.show_save = true;
              _this.show_submit = true;
            }else if(info.status >1 && info.status <= 7){
              _this.no_click = true;
            }
            if(info.status<2){
              info.apply_time = new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate();
            }
            _this.invoice = info;//基本信息

            _this.invoice_detail = detail;//货物列表
            if(_this.invoice_detail.length>0){
              _this.show_goods = true;
              _this.no_tax_show = true;
              _this.no_submit = false;//提交按钮可点击
            }
            _this.invoice_tax.tax_amount = tax_info.tax_included_amount;
            _this.invoice_tax.amount = tax_info.no_tax_included_amount;
            _this.invoice_tax.tax = tax_info.tax;
            _this.no_sum_tax_amount = no_sum_tax_amount;//这个月不含税

            _this.tax_detail.tax_value = tax_list_data.tax_value_per;
            _this.tax_detail.tax_income = tax_list_data.tax_income_per;
            _this.tax_detail.tax_city = tax_list_data.tax_city_per;
            _this.tax_detail.tax_education = tax_list_data.tax_education_per;
            _this.tax_detail.tax_local_education = tax_list_data.tax_local_education_per;
            _this.pay_tax_detail = tax_list_data;

            //长度问题
            _this.num = detail.length;


            // _this.invoice = info;
            // _this.invoice = info;
            // console.log(info);
          }
        }).catch(function (err){
          console.info(err)
        })
      }else{
        Exclude({}).then(function (res){
          if(res.Code==200){
            _this.no_sum_tax_amount = res.Data.no_sum_tax_amount;//这个月不含税
            _this.unit_list = res.Data.unit_list;//单位
            _this.spec_list = res.Data.spec_list;//规格

          }
        }).catch(function (err){
          console.info(err)
        })
      }

    },
    invoiceList(){
      let _this = this
      _this.list = JSON.parse(localStorage.getItem('list'));
      _this.list_data = JSON.parse(localStorage.getItem('list'));
      // getInvoiceList({}).then(function (res){
      //   if(res.Code==200){
      //     const list = res.Data;
      //     _this.list = JSON.parse(list);
      //     _this.list_data = JSON.parse(list);
      //   }
      // }).catch(function (err){
      //   console.info(err)
      // })
    }
  }
};
</script>


<style lang="less" scoped>
.van-button-goods::after{
  border-bottom: unset;
}
.submit-button-btn{
  background-color:#ff552e;
}
.invoice-detail-page{
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: #F6F6F6;
  padding-bottom: 60px;
  :deep(.delete-button) {
    height: 100%;
  }
  :deep(.submit-data .van-field__control){
    text-align: right;
  }
  :deep(.invoice-add-button){
    width: 60vw;
    height: 6vh;
    background-color: #ffd9cd;
    border-radius: 4px;
    margin: 2vh auto;
    color: #ff552e;
    border: unset;
  }
  :deep(.invoice-desc){
    height: 25px;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
  }
  :global(.tax-edit .van-cell__title){
    text-align: left;
    color: #999999;
    font-size: 13px;
  }
  :global(.tax-edit .van-cell__value){
    color: #333333;
    font-size: 13px;
  }
  :deep(.van-cell__title){
    text-align: left;
    color: #999999;
    font-size: 13px;
  }
  :deep(.van-cell__value){
    color: #333333;
    font-size: 13px;
  }
  :global(.item-edit .van-cell__title){
      text-align: left;
      color: #999999;
      font-size: 13px;
  }
  :global(.item-edit .van-cell__value){
    color: #333333;
    font-size: 13px;
  }
}
.submit-button-group{
  bottom: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 8vh;
  background: #FFFFFF;
}
.submit-button-group{
  :deep(button){
    margin: auto 20px;
    height: 35px;
  }
}
.block-title{
  margin: 0 auto;
  padding: 10px 10px 10px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}
.goods-icon{
  right: 0;
  margin-left: 0.10667rem;
  color: #969799;
  float: right;
  height: 0.64rem;
  font-size: 0.42667rem;
  line-height: 0.64rem;
}

.invoice-info{
  margin-top: 10px;
  margin-bottom: 10px;
}
.invoice-end{
  margin-bottom: 10px;
}
.van-field__label{
  color: #999999;
  font-size: 13px;
}
.item-edit .van-field__control{

}
:global(.question_mark){
  width: 4rem;
  height: 3rem;
}
:global(.action_list){
  width: 7rem!important;
  margin-left: 4.6rem!important;
  margin-top: -0.5rem!important;
}
:global(.tax-pop-title){
  display: block;
  text-align: center;
  font-size: 14px;
}
.van-dialog{
  :global(.van-cell__value){
    color: #333333;
  }
  :global(.van-cell__title){
    text-align: left;
  }
}

</style>
